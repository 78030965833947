"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  year: '',
  month: '',
  day: '',
  hour: '',
  minute: '',
  am: 'AM',
  pm: 'PM'
};
exports["default"] = _default;