"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _en_US = _interopRequireDefault(require("../datepicker/locale/en_US"));

var _default = {
  okText: 'OK',
  dismissText: 'Cancel',
  extra: 'please select',
  DatePickerLocale: _en_US["default"]
};
exports["default"] = _default;