import _classCallCheck from '@babel/runtime/helpers/classCallCheck';
import _createClass from '@babel/runtime/helpers/createClass';
import _assertThisInitialized from '@babel/runtime/helpers/assertThisInitialized';
import _inherits from '@babel/runtime/helpers/inherits';
import _possibleConstructorReturn from '@babel/runtime/helpers/possibleConstructorReturn';
import _getPrototypeOf from '@babel/runtime/helpers/getPrototypeOf';
var _jsxFileName =
  '/home/runner/work/react-native-action-sheet/react-native-action-sheet/src/ActionSheet/index.tsx';
function _createSuper(Derived) {
  var hasNativeReflectConstruct = _isNativeReflectConstruct();
  return function _createSuperInternal() {
    var Super = _getPrototypeOf(Derived),
      result;
    if (hasNativeReflectConstruct) {
      var NewTarget = _getPrototypeOf(this).constructor;
      result = Reflect.construct(Super, arguments, NewTarget);
    } else {
      result = Super.apply(this, arguments);
    }
    return _possibleConstructorReturn(this, result);
  };
}
function _isNativeReflectConstruct() {
  if (typeof Reflect === 'undefined' || !Reflect.construct) return false;
  if (Reflect.construct.sham) return false;
  if (typeof Proxy === 'function') return true;
  try {
    Date.prototype.toString.call(Reflect.construct(Date, [], function () {}));
    return true;
  } catch (e) {
    return false;
  }
}
import * as React from 'react';
import {
  Animated,
  BackHandler,
  Easing,
  Modal,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import ActionGroup from './ActionGroup';
var OPACITY_ANIMATION_IN_TIME = 225;
var OPACITY_ANIMATION_OUT_TIME = 195;
var EASING_OUT = Easing.bezier(0.25, 0.46, 0.45, 0.94);
var EASING_IN = Easing.out(EASING_OUT);
var ESCAPE_KEY = 'Escape';
var ActionSheet = (function (_React$Component) {
  _inherits(ActionSheet, _React$Component);
  var _super = _createSuper(ActionSheet);
  function ActionSheet() {
    var _this;
    _classCallCheck(this, ActionSheet);
    for (
      var _len = arguments.length, args = new Array(_len), _key = 0;
      _key < _len;
      _key++
    ) {
      args[_key] = arguments[_key];
    }
    _this = _super.call.apply(_super, [this].concat(args));
    _this._actionSheetHeight = 360;
    _this.state = {
      isVisible: false,
      isAnimating: false,
      options: null,
      onSelect: null,
      overlayOpacity: new Animated.Value(0),
      sheetOpacity: new Animated.Value(0),
    };
    _this._deferAfterAnimation = undefined;
    _this._handleWebKeyDown = function (event) {
      if (event.key === ESCAPE_KEY && _this.state.isVisible) {
        event.preventDefault();
        _this._selectCancelButton();
      }
    };
    _this._setActionSheetHeight = function (_ref) {
      var nativeEvent = _ref.nativeEvent;
      return (_this._actionSheetHeight = nativeEvent.layout.height);
    };
    _this.showActionSheetWithOptions = function (options, onSelect) {
      var _this$state = _this.state,
        isVisible = _this$state.isVisible,
        overlayOpacity = _this$state.overlayOpacity,
        sheetOpacity = _this$state.sheetOpacity;
      if (isVisible) {
        _this._deferAfterAnimation = _this.showActionSheetWithOptions.bind(
          _assertThisInitialized(_this),
          options,
          onSelect
        );
        return;
      }
      _this.setState({
        options: options,
        onSelect: onSelect,
        isVisible: true,
        isAnimating: true,
      });
      overlayOpacity.setValue(0);
      sheetOpacity.setValue(0);
      Animated.parallel([
        Animated.timing(overlayOpacity, {
          toValue: 0.32,
          easing: EASING_OUT,
          duration: OPACITY_ANIMATION_IN_TIME,
          useNativeDriver: _this.props.useNativeDriver,
        }),
        Animated.timing(sheetOpacity, {
          toValue: 1,
          easing: EASING_OUT,
          duration: OPACITY_ANIMATION_IN_TIME,
          useNativeDriver: _this.props.useNativeDriver,
        }),
      ]).start(function (result) {
        if (result.finished) {
          _this.setState({ isAnimating: false });
          _this._deferAfterAnimation = undefined;
        }
      });
      BackHandler.addEventListener(
        'actionSheetHardwareBackPress',
        _this._selectCancelButton
      );
    };
    _this._selectCancelButton = function () {
      var options = _this.state.options;
      if (!options) {
        return false;
      }
      if (typeof options.cancelButtonIndex === 'undefined') {
        return false;
      } else if (typeof options.cancelButtonIndex === 'number') {
        return _this._onSelect(options.cancelButtonIndex);
      } else {
        return _this._animateOut();
      }
    };
    _this._onSelect = function (index) {
      var _this$state2 = _this.state,
        isAnimating = _this$state2.isAnimating,
        onSelect = _this$state2.onSelect;
      if (isAnimating) {
        return false;
      }
      if (onSelect) {
        _this._deferAfterAnimation = onSelect.bind(
          _assertThisInitialized(_this),
          index
        );
      }
      return _this._animateOut();
    };
    _this._animateOut = function () {
      var _this$state3 = _this.state,
        isAnimating = _this$state3.isAnimating,
        overlayOpacity = _this$state3.overlayOpacity,
        sheetOpacity = _this$state3.sheetOpacity;
      if (isAnimating) {
        return false;
      }
      BackHandler.removeEventListener(
        'actionSheetHardwareBackPress',
        _this._selectCancelButton
      );
      _this.setState({ isAnimating: true });
      Animated.parallel([
        Animated.timing(overlayOpacity, {
          toValue: 0,
          easing: EASING_IN,
          duration: OPACITY_ANIMATION_OUT_TIME,
          useNativeDriver: _this.props.useNativeDriver,
        }),
        Animated.timing(sheetOpacity, {
          toValue: 0,
          easing: EASING_IN,
          duration: OPACITY_ANIMATION_OUT_TIME,
          useNativeDriver: _this.props.useNativeDriver,
        }),
      ]).start(function (result) {
        if (result.finished) {
          _this.setState({ isVisible: false, isAnimating: false });
          if (_this._deferAfterAnimation) {
            _this._deferAfterAnimation();
          }
        }
      });
      return true;
    };
    return _this;
  }
  _createClass(ActionSheet, [
    {
      key: 'componentDidMount',
      value: function componentDidMount() {
        if (Platform.OS === 'web') {
          document.addEventListener('keydown', this._handleWebKeyDown);
        }
      },
    },
    {
      key: 'componentWillUnmount',
      value: function componentWillUnmount() {
        if (Platform.OS === 'web') {
          document.removeEventListener('keydown', this._handleWebKeyDown);
        }
      },
    },
    {
      key: 'render',
      value: function render() {
        var _this$state4 = this.state,
          isVisible = _this$state4.isVisible,
          overlayOpacity = _this$state4.overlayOpacity,
          options = _this$state4.options;
        var useModal = options
          ? options.autoFocus || options.useModal === true
          : false;
        var overlay = isVisible
          ? React.createElement(Animated.View, {
              style: [styles.overlay, { opacity: overlayOpacity }],
              __source: {
                fileName: _jsxFileName,
                lineNumber: 82,
                columnNumber: 7,
              },
              onClick: this._selectCancelButton,
            })
          : null;
        var appContent = React.createElement(
          View,
          {
            style: styles.flexContainer,
            importantForAccessibility: isVisible
              ? 'no-hide-descendants'
              : 'auto',
            __source: {
              fileName: _jsxFileName,
              lineNumber: 94,
              columnNumber: 7,
            },
          },
          React.Children.only(this.props.children)
        );
        return React.createElement(
          View,
          {
            pointerEvents: this.props.pointerEvents,
            style: styles.flexContainer,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 102,
              columnNumber: 7,
            },
          },
          appContent,
          isVisible &&
            !useModal &&
            React.createElement(
              React.Fragment,
              null,
              overlay,
              this._renderSheet()
            ),
          isVisible &&
            useModal &&
            React.createElement(
              Modal,
              {
                animationType: 'none',
                transparent: true,
                onRequestClose: this._selectCancelButton,
                __source: {
                  fileName: _jsxFileName,
                  lineNumber: 111,
                  columnNumber: 11,
                },
              },
              overlay,
              this._renderSheet()
            )
        );
      },
    },
    {
      key: '_renderSheet',
      value: function _renderSheet() {
        var _this$state5 = this.state,
          options = _this$state5.options,
          isAnimating = _this$state5.isAnimating,
          sheetOpacity = _this$state5.sheetOpacity;
        if (!options) {
          return null;
        }
        var optionsArray = options.options,
          icons = options.icons,
          tintIcons = options.tintIcons,
          destructiveButtonIndex = options.destructiveButtonIndex,
          disabledButtonIndices = options.disabledButtonIndices,
          destructiveColor = options.destructiveColor,
          textStyle = options.textStyle,
          tintColor = options.tintColor,
          title = options.title,
          titleTextStyle = options.titleTextStyle,
          message = options.message,
          messageTextStyle = options.messageTextStyle,
          autoFocus = options.autoFocus,
          showSeparators = options.showSeparators,
          containerStyle = options.containerStyle,
          separatorStyle = options.separatorStyle;
        return React.createElement(
          TouchableWithoutFeedback,
          {
            importantForAccessibility: 'yes',
            onPress: this._selectCancelButton,
            __source: {
              fileName: _jsxFileName,
              lineNumber: 146,
              columnNumber: 7,
            },
          },
          React.createElement(
            Animated.View,
            {
              needsOffscreenAlphaCompositing: isAnimating,
              style: [
                styles.sheetContainer,
                {
                  opacity: sheetOpacity,
                  transform: [
                    {
                      translateY: sheetOpacity.interpolate({
                        inputRange: [0, 1],
                        outputRange: [this._actionSheetHeight, 0],
                      }),
                    },
                  ]
                },
              ],
              __source: {
                fileName: _jsxFileName,
                lineNumber: 147,
                columnNumber: 9,
              },
            },
            React.createElement(
              View,
              {
                style: styles.sheet,
                onLayout: this._setActionSheetHeight,
                __source: {
                  fileName: _jsxFileName,
                  lineNumber: 163,
                  columnNumber: 11,
                },
              },
              React.createElement(ActionGroup, {
                options: optionsArray,
                icons: icons,
                tintIcons: tintIcons === undefined ? true : tintIcons,
                destructiveButtonIndex: destructiveButtonIndex,
                destructiveColor: destructiveColor,
                disabledButtonIndices: disabledButtonIndices,
                onSelect: this._onSelect,
                startIndex: 0,
                length: optionsArray.length,
                textStyle: textStyle || {},
                tintColor: tintColor,
                title: title || undefined,
                titleTextStyle: titleTextStyle,
                message: message || undefined,
                messageTextStyle: messageTextStyle,
                autoFocus: autoFocus,
                showSeparators: showSeparators,
                containerStyle: containerStyle,
                separatorStyle: separatorStyle,
                __source: {
                  fileName: _jsxFileName,
                  lineNumber: 164,
                  columnNumber: 13,
                },
              })
            )
          )
        );
      },
    },
  ]);
  return ActionSheet;
})(React.Component);
ActionSheet.defaultProps = { useNativeDriver: true };
export { ActionSheet as default };
var styles = StyleSheet.create({
  flexContainer: { flex: 1 },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'black',
  },
  sheetContainer: {
    // position: 'absolute',
    position: 'sticky',
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  sheet: { flex: 1, backgroundColor: 'transparent' },
});
