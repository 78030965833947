"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _en_US = _interopRequireDefault(require("../date-picker-view/locale/en_US"));

var _en_US2 = _interopRequireDefault(require("../date-picker/locale/en_US"));

var _en_US3 = _interopRequireDefault(require("../input-item/locale/en_US"));

var _en_US4 = _interopRequireDefault(require("../list-view/locale/en_US"));

var _en_US5 = _interopRequireDefault(require("../modal/locale/en_US"));

var _en_US6 = _interopRequireDefault(require("../pagination/locale/en_US"));

var _en_US7 = _interopRequireDefault(require("../picker/locale/en_US"));

var _en_US8 = _interopRequireDefault(require("../search-bar/locale/en_US"));

var _default = {
  locale: 'en',
  DatePicker: _en_US2["default"],
  DatePickerView: _en_US["default"],
  InputItem: _en_US3["default"],
  Modal: _en_US5["default"],
  Pagination: _en_US6["default"],
  Picker: _en_US7["default"],
  SearchBar: _en_US8["default"],
  ListView: _en_US4["default"]
};
exports["default"] = _default;